// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
    box-sizing: border-box;
}
html, body {
    background-color: $color-background;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after { box-sizing: inherit }


ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

img {
    display: block;
    width: 100%;
}

audio, canvas, iframe, img, svg, video {
    width: 100%;
}

.emoji {
	width: 16px;
	height:16px;
	margin-top:-3px;
	display:inline-block;
}

.i8b-table-container {
	overflow: scroll;
}

.i8b-table-shadow {
	box-shadow: inset -15px 0 10px -10px #cccccc;
	z-index: 999;
	height: 100%;
	width: 60px;
	position: absolute;
	right: 0;
	top: 0;
}

.i8b-table-wrapper {
	position:relative;
} 

.i8b-input-table table {
	table-layout: fixed;
}

.i8b-input-table table.auto-layout {
	table-layout: auto;
}

.i8b-input-table table td {
	padding: 5px 5px;
}

.i8b-input-table table th {
	padding: 5px 0px;
}

.i8b-input-table table th.i8b-vertical-text {
	padding: 30px 0px;
	width: 60px;
}

.i8b-input-table table th.i8b-vertical-text.no-padding {
	padding: 5px 0px;
}

.i8b-input-table table th.i8b-vertical-text p {
	margin: 0px
}

.i8bit-table-product-title {
	font-size:14px;
}

.i8b-product-title-text {
	white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
    display: block;
    overflow: hidden;
    font-weight: bold;
}

.i8b-short-title {
	white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
    display: block;
    overflow: hidden;
}

.i8b-shorter-title {
	white-space: nowrap;
    text-overflow: ellipsis;
    width: 110px;
    display: block;
    overflow: hidden;
}

.i8bit-table-product-title-head {
	width:160px;
}

.fixed-table-head thead {
	background-color: #fff
}

.i8bit-table-variant-title-head {
	width:10%;
}

.i8b-table-action-head {
	width:15%;
}

.i8b-table-date {
	font-size:14px;
}

.i8b-number-input {
	width: 50px;
}

.i8b-vertical-text {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	vertical-align: middle !important;
	padding: 10px 0px;
	width:50px;
}

.i8b-filterbox {
	padding: 15px;
}

.i8b-filterbox-action-buttons {
	margin:10px 0px;
}


/* Animation */
@-webkit-keyframes loading {
  to { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes loading {
	to { -moz-transform: rotate(360deg); }
}
@-ms-keyframes loading {
	to { -ms-transform: rotate(360deg); }
}
@keyframes loading {
	to { transform: rotate(360deg); }
}

/* Loader (*/
.i8b-loading {

	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: transparent;
	
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	border-bottom: 2px solid #c1c1c1;
	border-left: 2px solid #c1c1c1;
	
	-webkit-animation: loading 0.9s infinite linear;
	-moz-animation: loading 0.9s infinite linear;
	-ms-animation: loading 0.9s infinite linear;
	animation: loading 0.9s infinite linear;
}

.i8b-loading-fix-right {
	position: absolute;
	right: 6px;
	top:9px;
}

.i8b-table-header-small {
	font-size: .7rem !important
}

.uk-icon {
	max-width: 16px;
}

.uk-icon>* {
	transform: translate(0,0);
	max-width: 16px;
}

.uk-navbar-dropdown {
	width: 220px;
}

.i8b-filter-apply {
	width: 100%;
}

.i8b-filter-box {
	background-color: #fff;
	padding: 10px
}

.i8b-button-tiny {
	padding: 0px 6px;
	font-size: 10px;
}

.sub-row {
	display: none
}

.table-expand {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-o-user-select: none;
	user-select: none;
	font-size: 0.9em;
	color: blue;
}

.i8b-table-group-title {
	color:#fff;
	font-weight: bold;
	border-bottom: 1px solid #666 !important;
	padding-top: 20px !important;
}

.i8b-table-group-title a {
	color:#fff;
	text-decoration: underline;
}

.i8b-table-group-title.incoming, .i8b-table-status-column-incoming {
	background-color: #80CBC4;
}

.i8b-table-group-title.outgoing, .i8b-table-status-column-outgoing {
	background-color: #ef9a9a;
}

.i8b-table-group-title.status, .i8b-table-status-column-status {
	background-color: #9FA8DA;
}

.i8b-table-status-column-incoming a, .i8b-table-status-column-outgoing a, .i8b-table-status-column-status a {
	color:#666;
	text-decoration: underline;
}
.focus-column {
	background-color: #4285f4 !important;
	color: #fff !important;
}

.i8b-filter-form .uk-grid-margin {
  margin-top: 10px;
}

.i8b-first-box {
	padding-top: 0px;
}

.tab-container .tabs ul li {
	display: inline-block;
	margin-bottom: 10px;
}

.tab-container .tabs ul li a {
	text-decoration: none;
	color:#9e9e9e;
	padding: 15px;
	background-color: #dbdbdb;
	font-size: 1.2em;
}

.tab-container .tabs ul li.active {
	
}

.tab-container .tabs ul li.active a {
	color:#666;
	background-color: #fff;
}

.i8b-resolve-modal-row {
	margin-top: 10px;
	padding-left:5px;
}