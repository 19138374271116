// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


$border-radius: 5px;

$color-background: #EEE;

$color-primary: #4285f4;
$color-primary-dark: #3a75d7;
$color-primary-dark-hover: #3f7fea;

$color-secondary: #636363;

$color-navbar: $color-primary;

$bright-grey: #f9f9f9;

$color-button-default: $color-background;