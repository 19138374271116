nav {
    background-color: $color-navbar;
    @extend %box-shadow-z2;

    .uk-logo {
        img {
            width: 100%;
            max-width: 100px;
        }
    }
    .uk-navbar-nav {
        li:hover > a{

            color: #FFF;
            background-color: $color-primary-dark-hover;            
        }

        li.uk-active > a {
            color: #FFF;
                background-color: $color-primary-dark;
        }

        a {
            color: #FFF;

            &:hover {
                color: #FFF;
            }
        }

        
    }

    .uk-navbar-dropdown-nav {
        li {
            &.uk-nav-header {
                padding: 10px 25px 5px 15px;
                color: #000;
                font-size:14px;
                margin-top:15px;
            }
        }
    }

    .uk-navbar-dropdown {
        padding: 0px;

        li {
            a {
                color : #999;
                background-color: #fff;
                padding: 5px 25px 5px 25px;
                font-size: 13px;
            }
        }
    }
}