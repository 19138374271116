// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.icon-inline {
    img {
        max-width: 12px;
        display: inline-block;
        margin-left: 5px;
    }
}

// ===========================
// Depth helpers
// ===========================

%box-shadow-z0 {
	box-shadow: 0 2px 3px #CFCFCF !important;
}

%box-shadow-z1 {
	box-shadow: 0 1px 3px rgba(0,0,0, 0.12), 0 1px 2px rgba(0,0,0, 0.24) !important;
}

%box-shadow-z2 {
	box-shadow: 0 3px 6px rgba(0,0,0, 0.16), 0 3px 6px rgba(0,0,0, 0.23) !important;
}

%box-shadow-z3 {
	box-shadow: 0 10px 20px rgba(0,0,0, 0.19), 0 6px 6px rgba(0,0,0, 0.23) !important;
}

%box-shadow-z4 {
	box-shadow: 0 14px 28px rgba(0,0,0, 0.25), 0 10px 10px rgba(0,0,0, 0.22) !important;
}

%box-shadow-z5 {
	box-shadow: 0 19px 38px rgba(0,0,0, 0.30), 0 15px 12px rgba(0,0,0, 0.22) !important;
}
